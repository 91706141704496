import DateConverter from "../../services/converters/date_converter";
import moment from "moment";

export class ScanDatasetStats {
  id: number;
  organizationName: string;
  scanDatasetId: number;
  projectName: string;
  floor: string;
  scanDatasetName: string;
  pipelineStartTime: Date;
  pipelineEndTime: Date;
  pipelineStatus: string;
  qaCompleteTime: Date;
  qaStartTime: Date;
  pipelineRuntime: number;
  qaRuntime: number;
  totalRuntime: number;
  squareFootage: number;

  realityCaptureName?: string;
  realityCaptureType?: string;
  realityCaptureUploadedOn?: Date;

  constructor(props: ScanDatasetStatsProps) {
    this.organizationName = props.organizationName;
    this.projectName = props.projectName;
    this.scanDatasetId = props.scanDatasetId;
    this.scanDatasetName = props.scanDatasetName;
    this.pipelineStatus = props.pipelineStatus;
    this.squareFootage = props.squareFootage;
    this.floor = props.floor;
    this.realityCaptureName = props.realityCaptureName;
    this.realityCaptureType = props.realityCaptureType;
    this.realityCaptureUploadedOn = DateConverter.instantToDate(props.realityCaptureUploadedOn);
    this.pipelineStartTime = DateConverter.instantToDate(props.pipelineStartTime)
    this.pipelineEndTime = DateConverter.instantToDate(props.pipelineEndTime)
    this.qaStartTime = DateConverter.instantToDate(props.qaStartTime)
    this.qaCompleteTime = DateConverter.instantToDate(props.qaCompleteTime)
    if(this.qaCompleteTime && this.qaStartTime )
      this.qaRuntime = Math.round(moment.duration((this.qaCompleteTime).valueOf() - this.qaStartTime.valueOf()).asHours() * 1000)/1000
    if(this.pipelineEndTime && this.pipelineStartTime)
      this.pipelineRuntime = Math.round(moment.duration((this.pipelineEndTime.valueOf() - this.pipelineStartTime.valueOf())).asHours() * 1000) /1000
    if(this.qaCompleteTime && this.pipelineStartTime)
      this.totalRuntime =  Math.round(moment.duration(this.qaCompleteTime.valueOf() - this.pipelineStartTime.valueOf()).asHours() * 1000) / 1000
  }
}


type ScanDatasetStatsProps = {
  organizationName: string;
  scanDatasetId: number;
  projectName: string;
  floor: string;
  scanDatasetName: string;
  pipelineStartTime: number;
  pipelineEndTime: number;
  pipelineStatus: string;
  qaCompleteTime: number;
  qaStartTime: number;
  squareFootage: number;

  realityCaptureName?: string;
  realityCaptureType?: string;
  realityCaptureUploadedOn?: number;
}